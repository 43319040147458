import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { isAuthenticated, isNotAuthenticated, isApprover } from '../../policies'
import { PT, USTATUS } from '../../config/constant'
import { IntlProvider } from 'react-intl'
import { messages } from '../../lang'
import moment from 'moment'
import NavigationBar from '../NavigationBar'
import Alert from '../Alert'
import { storage } from '../../utils'
import { bindActionCreators } from 'redux'
import { getProfile } from '../../store/actions/profile'
import { getMaster } from '../../store/actions/master'
import { LANG } from '../../config/constant'
import { Container, Modal, Spinner } from '../../components/common'
import ChangePassword from '../ChangePassword'
import Loadable from 'react-loadable'
import _ from 'lodash'
import './_index.scss'

interface IProp {
  getProfile: () => any;
  getMaster: () => any;
  lang: LANG;
  isSignedIn: boolean;
  userStatus: USTATUS;
  pastDelay?: any;
  loading?: any;
}

interface IState {
}

const Loading = (props: any) => {
  if (props.pastDelay) {
    return <Spinner />
  } else {
    return null
  }
}

const loadComponent = (name: string) => {
  return Loadable({
    loader: () => import('../' + name),
    loading: Loading,
    delay: 100
  })
}

export class Wrapper extends React.Component<IProp, IState>{
  private changePasswordModal: React.RefObject<Modal>

  constructor(props: IProp) {
    super(props)

    this.changePasswordModal = React.createRef<Modal>()
  }

  componentDidMount() {
    if (storage.isTokenExist()) {
      this.props.getProfile()
      this.props.getMaster()
    }
  }

  componentDidUpdate(prevProps: IProp) {
    if (
      !_.isEqual(prevProps, this.props) &&
      this.props.userStatus === USTATUS.NEW
    ) {
      this.changePasswordModal.current!.openModal()
    }
  }

  render() {
    const {
      lang,
      isSignedIn
    } = this.props

    // set locale on render
    moment.locale(lang)

    return (
      <IntlProvider
        locale={lang}
        messages={messages[lang]}>
        <>
          <Container
            className="wrapper"
            isFull={!isSignedIn}>
            <Router>
              <>
                <Switch>
                  <Route
                    exact
                    path={PT.SIGNIN}
                    component={isNotAuthenticated(loadComponent('Signin'))} />
                  <Route
                    exact
                    path={PT.HOMEPAGE}
                    component={isAuthenticated(loadComponent('Homepage'))} />
                  <Route
                    exact
                    path={PT.ATTENDANCE}
                    component={isAuthenticated(loadComponent('Attendance'))} />
                  <Route
                    exact
                    path={PT.APPROVAL}
                    component={isAuthenticated(isApprover(loadComponent('Approval')))} />
                  <Route
                    exact
                    path={PT.SETTING}
                    component={isAuthenticated(loadComponent('Setting'))} />
                  <Route
                    exact
                    path={PT.INBOX}
                    component={isAuthenticated(loadComponent('Inbox'))} />
                  <Redirect
                    to={PT.SIGNIN} />
                </Switch>
                <Modal
                  mode={4}
                  hideClose
                  content={<ChangePassword onClosedModal={() => this.changePasswordModal.current!.closeModal()} isNew />}
                  ref={this.changePasswordModal}
                />
                {isSignedIn &&
                  <NavigationBar />
                }
              </>
            </Router>
          </Container>
          <Alert />
        </>
      </IntlProvider>
    )
  }
}

const mapStateToProps = (state: any) => ({
  lang: state.config.lang,
  isSignedIn: state.profile.isSignedIn,
  userStatus: state.profile.data.status
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getProfile,
  getMaster
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)