import axios from "axios";
import { storage } from "../utils";
import { store } from "../store";
import { signout } from "../store/actions/signout";
import { showAlert } from "../store/actions/alert";
import env from "../config/environment";
import querystring from "query-string";
import {
  IProfileChangePassReq,
  ISigninReq,
  IAttReq,
  IAttStartReq,
  IAttEndReq,
  IAppRejectReq,
  IInboxReq,
  IEmergencyReq,
  Compliance,
} from "../config/interface";

const baseUrl = env.api;
const api = {
  signin: (signin: ISigninReq) => {
    return axios.post(`${baseUrl}/auth/user/login`, signin);
  },
  getProfile: () => {
    return axios.get(`${baseUrl}/users/me`);
  },
  getProfileStat: () => {
    return axios.get(`${baseUrl}/users/me/stats`);
  },
  getApprover: () => {
    return axios.get(`${baseUrl}/users/me/approver`);
  },
  getAttendance: (query: IAttReq) => {
    return axios.get(
      `${baseUrl}/attendance?${querystring.stringify(query as any)}`
    );
  },
  getActiveAttendance: () => {
    return axios.get(`${baseUrl}/attendance/active`);
  },
  getApproval: (query: IAttReq) => {
    return axios.get(
      `${baseUrl}/attendance/approval?${querystring.stringify(query as any)}`
    );
  },
  getAttendanceDetail: (id: number) => {
    return axios.get(`${baseUrl}/attendance/${id}`);
  },
  changePassword: (req: IProfileChangePassReq) => {
    return axios.patch(`${baseUrl}/users/me`, req);
  },
  startAttendance: (req: IAttStartReq) => {
    return axios.post(`${baseUrl}/attendance`, req);
  },
  deleteAttendance: (id: number) => {
    return axios.delete(`${baseUrl}/attendance/${id}`);
  },
  endAttendance: (id: number, req: IAttEndReq) => {
    return axios.post(`${baseUrl}/attendance/${id}/submit`, req);
  },
  reviseAttendance: (id: number, req: IAttEndReq) => {
    return axios.post(`${baseUrl}/attendance/${id}/revision/submit`, req);
  },
  approveAttendance: (id: number) => {
    return axios.post(`${baseUrl}/attendance/${id}/approve`);
  },
  rejectAttendance: (id: number, req: IAppRejectReq) => {
    return axios.post(`${baseUrl}/attendance/${id}/reject`, req);
  },
  uploadAttachment: (req: FormData) => {
    return axios.post(`${baseUrl}/media/image`, req);
  },
  getInbox: (query?: IInboxReq) => {
    return axios.get(
      `${baseUrl}/inbox${
        query ? "?" + querystring.stringify(query as any) : ""
      }`
    );
  },
  getInboxDetail: (id: number) => {
    return axios.get(`${baseUrl}/inbox/${id}`);
  },
  markAsRead: (id: number) => {
    return axios.post(`${baseUrl}/inbox/${id}/read`);
  },
  getEmergency: () => {
    return axios.get(`${baseUrl}/users/me/emergency-messages`);
  },
  setEmergencyRes: (id: number, req: IEmergencyReq) => {
    return axios.post(
      `${baseUrl}/users/me/emergency-messages/${id}/response`,
      req
    );
  },
  sendCompliance: (payload: Compliance) => {
    return axios.post(`${baseUrl}/messages`, payload);
  },
  uploadComplianceAttachment: (payload: FormData) => {
    return axios.post(`${baseUrl}/media/attachment`, payload);
  },
};

axios.interceptors.request.use(
  (config) => {
    const auth = storage.getToken();
    if (auth) {
      config.headers.authorization = auth;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a 401 response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.pathname !== "/signin"
    ) {
      if (error.response.data && error.response.data.error_code === 4003) {
        store.dispatch<any>(
          showAlert({
            type: "warning",
            message: "4003",
          })
        );
      }

      store.dispatch<any>(signout());
    }

    return Promise.reject(error);
  }
);

export { api as default, baseUrl };
