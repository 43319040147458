import React from 'react'
import classnames from 'classnames'
import { intlShape } from 'react-intl'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button } from '../../components/common'
import { hideAlert } from '../../store/actions/alert'
import './_index.scss'

interface IProp {
  className?: string;
  isSignedIn: boolean;
  hideAlert: () => any;
  alert: {
    isShowing: boolean;
    type: string;
    message: string;
  };
}

interface IState {
}

class Alert extends React.Component<IProp, IState>{
  static contextTypes = {
    intl: intlShape,
  }

  render() {
    const {
      className,
      isSignedIn,
      alert,
      hideAlert
    } = this.props

    const {
      intl
    } = this.context

    let startAutoClose

    if(alert.isShowing){
      clearTimeout(startAutoClose)
      startAutoClose = setTimeout(() => {
        hideAlert()
      }, 12000)
    }else{
      clearTimeout(startAutoClose)
    }

    const classes = classnames('alert',
      `alert--${alert.type}`,
      { 'alert--signed': isSignedIn },
      { 'alert--showed': alert.isShowing },
      className)
      
    return (
      <>
        <div 
          className={classes}
        >
          <div className="alert__content">
            { alert.message && 
              intl.formatMessage({ id: alert.message, defaultMessage: alert.message})
            }
          </div>
          <Button 
            type="inline"
            icon="close"
            onClick={() => hideAlert()}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  alert: state.alert,
  isSignedIn: state.profile.isSignedIn
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  hideAlert
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Alert)

