import React from 'react'
import classnames from 'classnames'
import { intlShape } from 'react-intl'
import Spinner from '../Spinner'
import Icon from '../Icon'
import './_index.scss'

interface IProp{
  className?: string;
  type?: string;
  label?: string;
  icon?: any;
  color?: string;
  showSpinner?: boolean;
  onClick?: any;
  href?: string;
  size?: string;
  disabled?: boolean;
}

interface IState{
}

class Button extends React.Component<IProp, IState>{
  static contextTypes = {
    intl: intlShape,
  }

  render() {
    const {
      className,
      type,
      label,
      icon,
      color,
      href,
      showSpinner,
      size,
      ...others
    } = this.props

    const {
      intl
    } = this.context

    const classes = classnames('button',
      `button--${color}`,
      `button--${type}`,
      `button--${size}`,
      {'button--disabled': showSpinner},
      className)
      
    return (
      <>
        { href ? 
          <a
            className={classes}
            href={href}
            {...others}>
            <div className="button__content">
              { icon && 
                <Icon data={icon} />
              }
              { label && 
                intl.formatMessage({ id: label, defaultMessage: label})
              }
            </div>
          </a>
          : 
          <button
            disabled={showSpinner}
            className={classes}
            {...others}>
            <div className="button__content">
              { icon && !showSpinner &&
                <Icon data={icon} />
              }
              { label && !showSpinner && 
                intl.formatMessage({ id: label, defaultMessage: label})
              }
              { showSpinner &&
                <Spinner />
              }
            </div>
          </button>
        }
      </>
    )
  }
}

export default Button