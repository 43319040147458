
import { LANG, STATUS, MESSAGE_TYPE } from './constant'
import moment from 'moment'

export const settings = {
  lang: LANG.ID,
  att: { 
    start: moment().startOf('day').subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment().endOf('day').format('YYYY-MM-DD'),
    isWorking: [
      { isTranslate: true, label: "labelWorking", value: true },
      { isTranslate: true, label: "labelNotWorking", value: false },
    ],
    status: [
      { isTranslate: true, label: "labelDraft", value: STATUS.DRAFT },
      { isTranslate: true, label: "labelSubmitted", value: STATUS.SUBMITTED },
      { isTranslate: true, label: "labelOnRevision", value: STATUS.ON_REVISION },
      { isTranslate: true, label: "labelApproved", value: STATUS.APPROVED },
      { isTranslate: true, label: "labelDeleted", value: STATUS.DELETED }
    ],
    dir: 'desc',
    minDate: moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD')
  },
  app: {
    start: moment().startOf('day').subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment().endOf('day').format('YYYY-MM-DD'),
    isWorking: [
      { isTranslate: true, label: 'labelWorking', value: true },
      { isTranslate: true, label: 'labelNotWorking', value: false }
    ],
    status: [
      { isTranslate: true, label: 'labelSubmitted', value: STATUS.SUBMITTED },
      { isTranslate: true, label: 'labelOnRevision', value: STATUS.ON_REVISION }
    ],
    dir: 'desc'
  },
  inbox:{
    type: 0
  }
}