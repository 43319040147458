import React from 'react'
import { FormattedMessage } from 'react-intl'

const isEmpty = (value: string) => {
  return typeof value === 'undefined' || value === undefined || value === '' ? true : false
}

export const required = (value: string) => {
  return isEmpty(value) ? <FormattedMessage id="validationRequired" defaultMessage="validationRequired" /> : undefined
}

export const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? <FormattedMessage id="validationInvalidEmail" defaultMessage="validationInvalidEmail" />
    : undefined

const exactLength = (limit: number) => (value: string) => {
  return value && value.length !== limit ?
    <FormattedMessage id="validationExactLength" defaultMessage="validationExactLength"
      values={{ value: limit }} />
    : undefined
}

export const isNumber = (value: number) => 
  value &&  isNaN(value) ?
    <FormattedMessage id="validationIsNumber" defaultMessage="validationIsNumber" />
    : undefined

export const passwordRegex = (value: string) => {
  return !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g.test(value) ?
    <FormattedMessage id="validationPasswordReq" defaultMessage="validationPasswordReq" />
    : undefined
}

export const passwordsMustMatch = (value: string, allValues: any) => {
  return allValues.new_password !== value ?
    <FormattedMessage id="validationPasswordMatch" defaultMessage="validationPasswordMatch" /> : undefined
}

export const newPasswordsCantMatch = (value: string, allValues: any) => {
  return allValues.old_password === value ?
    <FormattedMessage id="validationPasswordNew" defaultMessage="validationPasswordNew" /> : undefined
}

export const passwordUpLow8 = (value: string) => {
  return !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g.test(value) ?
    <FormattedMessage 
      id="validationPasswordUpLowNum8" 
      defaultMessage="validationPasswordUpLowNum8"
    />
    : undefined
}

export const minLength = (min: number) => (value: string) => {
  return value && value.length < min ?
    <FormattedMessage id="validationMinLength" defaultMessage="validationMinLength"
      values={{ value: min }} />
    : undefined
}

export const exactLength16 = exactLength(16)
export const exactLength3 = exactLength(3)
export const exactLength4 = exactLength(4)
export const minLength5 = minLength(5)