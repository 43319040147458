import { storage } from '../../utils'
import { PT } from '../../config/constant'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'

/**
 * wrap component to make it accessable only if user is authenticated
 * storage.isTokenExist should return true
 */
const isApprover: any = connectedRouterRedirect({
  redirectPath: PT.HOMEPAGE,
  authenticatedSelector: () => {
    const profile = storage.getProfile()
    const isAllowed = profile ? JSON.parse(profile).isApprover : false
    
    return isAllowed
  }
})

export default isApprover