import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { store, history } from './store'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import { ALERT } from './store/definitions'

// Import main views
import Wrapper from './views/Wrapper'

if((process.env.REACT_APP_ENV as string).trim() !== 'development') Sentry.init({ 
  dsn: 'https://16831d6ef990492692852c9a9912e9e9@sentry.io/1543819',
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_VERSION,
  maxBreadcrumbs: 20,
  ignoreErrors: ['Network Error'],
})

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Wrapper/>
    </ConnectedRouter>
  </Provider>, 
  document.getElementById('root')
)

// Register service worker in production only
serviceWorker.register({ 
  onSuccess: (registration: any) => {
    if(registration.active &&
      registration.active.state === 'activated') store.dispatch<any>({
      type: ALERT.SHOW,
      alert: {
        type: 'warning',
        message: 'alert.newUpdate'
      }
    })
  }
})

window.addEventListener('offline', () => {
  store.dispatch<any>({
    type: ALERT.SHOW,
    alert: {
      type: 'error',
      message: 'alert.noInternet'
    }
  })
})

