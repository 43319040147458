// Import constant for local storage
import { LS, LANG } from '../../config/constant'
import { 
  IProfile, 
  IProfileStat, 
  IMaster, 
  IAtt, 
  IApp,
  IInbox,
  IEmergency
} from '../../config/interface'

// ------------------------------- //
// Return boolean if there is token 
export const isTokenExist = () => !!localStorage.getItem(LS.TOKEN)

// Set token to local storage
export const setToken = (token: string) => localStorage.setItem(LS.TOKEN, token)

// Get token
export const getToken = () => localStorage.getItem(LS.TOKEN)

// Remove token 
export const removeToken = () => localStorage.removeItem(LS.TOKEN)

// ------------------------------- //
// Get lang
export const getLang = () => localStorage.getItem(LS.LANG)

// Set lang to local storage
export const setLang = (lang: LANG) => localStorage.setItem(LS.LANG, lang)


// ------------------------------- //
// Get profile
export const getProfile = () => localStorage.getItem(LS.PROFILE)

// Set profile
export const setProfile = (data: IProfile) => localStorage.setItem(LS.PROFILE, JSON.stringify(data))

// Remove profile
export const removeProfile = () => localStorage.removeItem(LS.PROFILE)

// Get profile stat
export const getProfileStat = () => localStorage.getItem(LS.PROFILE_STAT)

// Set profile stat
export const setProfileStat = (data: IProfileStat) => localStorage.setItem(LS.PROFILE_STAT, JSON.stringify(data))

// Remove profile stat
export const removeProfileStat = () => localStorage.removeItem(LS.PROFILE_STAT)


// ------------------------------- //
// Get master
export const getMaster = () => localStorage.getItem(LS.MASTER)

// Set master
export const setMaster = (data: IMaster) => localStorage.setItem(LS.MASTER, JSON.stringify(data))

// Remove master
export const removeMaster = () => localStorage.removeItem(LS.MASTER)


// ------------------------------- //
// Get attendance
export const getAtt = () => localStorage.getItem(LS.ATT)
export const getAttLatest = () => localStorage.getItem(LS.ATT_LATEST)
export const getAttActive = () => localStorage.getItem(LS.ATT_ACTIVE)

// Set attendance
export const setAtt = (data: IAtt[]) => localStorage.setItem(LS.ATT, JSON.stringify(data))
export const setAttLatest = (data: IAtt[]) => localStorage.setItem(LS.ATT_LATEST, JSON.stringify(data))
export const setAttActive = (data: IAtt) => localStorage.setItem(LS.ATT_ACTIVE, JSON.stringify(data))

// Remove attendance
export const removeAtt = () => localStorage.removeItem(LS.ATT)
export const removeAttLatest = () => localStorage.removeItem(LS.ATT_LATEST)
export const removeAttActive = () => localStorage.removeItem(LS.ATT_ACTIVE)


// ------------------------------- //
// Get approval
export const getApp = () => localStorage.getItem(LS.APP)

// Set approval
export const setApp = (data: IApp[]) => localStorage.setItem(LS.APP, JSON.stringify(data))

// Remove approval
export const removeApp = () => localStorage.removeItem(LS.APP)


// ------------------------------- //
// Get inbox
export const getInbox = () => localStorage.getItem(LS.INBOX)
export const getRecent = () => localStorage.getItem(LS.RECENT)
export const getBroadcast = () => localStorage.getItem(LS.BROADCAST)
export const getEmergency = () => localStorage.getItem(LS.EMERGENCY)

// Set inbox
export const setInbox = (data: IInbox[]) => localStorage.setItem(LS.INBOX, JSON.stringify(data))
export const setRecent = (data: IInbox[]) => localStorage.setItem(LS.RECENT, JSON.stringify(data))
export const setBroadcast = (data: IInbox[]) => localStorage.setItem(LS.BROADCAST, JSON.stringify(data))
export const setEmergency = (data: IEmergency[]) => localStorage.setItem(LS.EMERGENCY, JSON.stringify(data))

// remove inbox
export const removeInbox = () => localStorage.removeItem(LS.INBOX)
export const removeRecent = () => localStorage.removeItem(LS.RECENT)
export const removeBroadcast = () => localStorage.removeItem(LS.BROADCAST)
export const removeEmergency = () => localStorage.removeItem(LS.EMERGENCY)