// Import react-intil method and locale
import { addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import id from 'react-intl/locale-data/id'

// import dictionary file for each language
import locale_en from './locales/en/en.json'
import locale_id from './locales/id/id.json'

// add locale date to react-intl context
addLocaleData([...en, ...id])

// export messages to provider
export const messages: any = {
  'en': locale_en,
  'id': locale_id
}

