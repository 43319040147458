import { RESET } from '../../definitions'
import { storage, cookie } from '../../../utils'

const signout = () => {
  return (dispatch: any) => {
    return new Promise((resolve) => {
      storage.removeToken()
      storage.removeAtt()
      storage.removeApp()
      storage.removeAttLatest()
      storage.removeAttActive()
      storage.removeMaster()
      storage.removeProfileStat()
      storage.removeProfile()
      storage.removeInbox()
      storage.removeRecent()
      storage.removeBroadcast()
      storage.removeEmergency()
      cookie.removeAppSetting()
      cookie.removeAttSetting()
      dispatch({ type: RESET.ALL }) 
      resolve()
    })
  }
}

export { signout }