import api from '../../../api'
import { MASTER } from '../../definitions'
import { storage, logger } from '../../../utils'
import { IApprover } from '../../../config/interface'

const getMaster = () => {  
  return (dispatch: any) => {
    // Dispatch temporary data from localstorage
    dispatch({ type: MASTER.GET_LOADING, isLoading: true })
    
    let temp = storage.getMaster()
    if(temp){
      dispatch({ 
        type: MASTER.GET, 
        data: JSON.parse(temp)
      })
    } 

    // Api call
    Promise.all([
      api.getApprover()
    ])
      .then((result: any ) => {
        const approver = result && result.length > 0 ? result[0].data.map( (approver: IApprover) => ({
          id: approver.id,
          name: approver.name
        })) : []

        const master = {
          approver
        }

        storage.setMaster(master)

        return dispatch({
          type: MASTER.GET,
          data: master
        })
      })
      .then(() => dispatch({ type: MASTER.GET_LOADING, isLoading: false }))
      .catch((err: any) => {
        logger(err, 'error', 'getMaster', false, err && err.response && err.response.data)
        dispatch({ type: MASTER.GET_LOADING, isLoading: false })
      })
  }
}

export { getMaster }