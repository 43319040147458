import React from 'react'
import classnames from 'classnames'
import './_index.scss'

interface IProp{
  children: any;
  isFull?: boolean;
  className?: string;
  isSidebarOnDesktop?: boolean;
}

interface IState{
}

export class Container extends React.Component<IProp, IState>{
  render(){
    const { children, isFull, className, isSidebarOnDesktop } = this.props
    const classes = 
      classnames('container',
        { 'container--full': isFull },
        { 'container--desktop-sidebar': isSidebarOnDesktop },
        className)

    return(
      <div className={classes}>
        {children}
      </div>
    )
  }
}

export default Container