import { storage } from '../../utils'
import { PT } from '../../config/constant'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'

/**
 * wrap component to make it accessable only if user is authenticated
 * storage.isTokenExist should return true
 */
const isAuthenticated: any = connectedRouterRedirect({
  redirectPath: PT.SIGNIN,
  authenticatedSelector: () => storage.isTokenExist()
})

export default isAuthenticated