import { APP, RESET } from '../../definitions'
import { settings } from '../../../config/config'
import { cookie } from '../../../utils'
import _ from 'lodash'

const getSetting = cookie.getAppSetting()
const initialSetting = getSetting ? JSON.parse(getSetting) : settings.app 

const initialState = {
  isLoading: false,
  isLoadingFilter: false,
  isApproving: false,
  isRejecting: false,
  approving: [],
  rejecting: [],
  data: [],
  filter: {
    start: initialSetting.start,
    end: initialSetting.end,
    isWorking: initialSetting.isWorking,
    status: initialSetting.status,
    dir: initialSetting.dir
  }
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case APP.GET:
      return Object.assign({}, {
        ...state,
        data: action.data
      })

    case APP.GET_LOADING:
      return Object.assign({}, {
        ...state,
        isLoading: action.isLoading
      })
    
    case APP.FILTER:
      return Object.assign({}, {
        ...state,
        filter: action.data
      })

    case APP.FILTER_LOADING:
      return Object.assign({}, {
        ...state,
        isLoadingFilter: action.isLoadingFilter
      })

    case APP.APPROVE_LOADING_START:
      return Object.assign({}, {
        ...state,
        isApproving: action.isApproving,
        approving: _.concat(state.approving, [action.id])
      })
    
    case APP.APPROVE_LOADING_END: 
      return Object.assign({}, {
        ...state,
        isApproving: action.isApproving,
        approving: _.pull(state.approving, action.id)
      })

    case APP.REJECT_LOADING_START:
      return Object.assign({}, {
        ...state,
        isRejecting: action.isRejecting,
        rejecting: _.concat(state.rejecting, [action.id])
      })

    case APP.REJECT_LOADING_END: 
      return Object.assign({}, {
        ...state,
        isRejecting: action.isRejecting,
        rejecting: _.pull(state.rejecting, action.id)
      })

    case APP.BULK_LOADING_START:
      return Object.assign({}, {
        ...state,
        isApproving: action.isApproving,
        approving: _.concat(state.approving, action.arrId)
      })

    case APP.BULK_LOADING_END:
      return Object.assign({}, {
        ...state,
        isApproving: action.isApproving,
        approving: _.xor(state.approving, action.arrId)
      })

    case RESET.ALL: 
      return initialState

    default:
      return state
  }
}