import { EMERGENCY, RESET } from '../../definitions'
import { IEmergency } from '../../../config/interface'

const initialState = {
  data: [] as IEmergency[]
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case EMERGENCY.GET:
      return Object.assign({}, {
        ...state,
        data: action.data
      })
    case RESET.ALL: 
      return initialState
    default:
      return state
  }
}