import { ALERT } from '../../definitions'

const showAlert = (alert: object) => {
  return (dispatch: any) => {
    dispatch({ 
      type: ALERT.SHOW, 
      alert 
    })
  }
}

const hideAlert = () => {
  return (dispatch: any) => {
    dispatch({ 
      type: ALERT.HIDE
    })
  }
}

export { showAlert, hideAlert }