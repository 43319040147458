import * as Sentry from '@sentry/browser'
import { IProfile } from '../../config/interface'
 
const logger = (error: any, level: 'warning' | 'error' |  'fatal' | 'info' = 'warning', context: string, isMessage: boolean = false, response?: any, request?: any) => {
  if(
    (process.env.REACT_APP_ENV as string).trim() === 'development' ||
    error && error.response && error.response.status === 401) return 

  Sentry.withScope((scope) => {
    scope.setLevel(
      level === 'error' ? Sentry.Severity.Error :
      level === 'fatal' ? Sentry.Severity.Fatal : 
      level === 'info' ? Sentry.Severity.Info : 
      Sentry.Severity.Warning)
      
    scope.setTag('context', context)

    if(response) scope.setExtra('response', response)
    if(request) scope.setExtra('request', request)
    
    if(isMessage){
      Sentry.captureMessage(error)
    }else{
      Sentry.captureException(error);
    }
  })
}

const initUserLog = (profile: IProfile) => {
  if((process.env.REACT_APP_ENV as string).trim() === 'development') return 

  Sentry.configureScope((scope) => {
    scope.setUser({
      employeeCode: profile.employeeCode
    })
  })
}

export { logger as default, initUserLog }
