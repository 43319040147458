import React from 'react'
import './_index.scss'

interface IProp{
  className?: string;
  data: any;
}

interface IState{
}

class Icon extends React.Component<IProp, IState>{
  render() {
    const {
      data
    } = this.props

    return (
      <>
        { typeof data === 'string' ? 
          <i className="material-icons">{data}</i> :
          data
        }
      </>
    )
  }
}

export default Icon