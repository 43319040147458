import { INBOX } from '../../definitions'
import api from '../../../api'
import { storage, logger, cookie } from '../../../utils'
import { ITYPE } from '../../../config/constant'
import { settings } from '../../../config/config'
import { IInboxReq, IInboxRes, IInboxDetailRes, IInboxContentRes } from '../../../config/interface'
import { getProfileStat } from '../profile'

export const getInbox = (type: 'inbox' | 'recent' | 'broadcast' = 'inbox', query?: IInboxReq) => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      let loadFunc = storage.getInbox
      let setFunc = storage.setInbox
      let loading = INBOX.GET_LOADING
      let action = INBOX.GET

      if(type === 'recent'){
        loadFunc = storage.getRecent
        setFunc = storage.setRecent
        loading = INBOX.RECENT_LOADING,
        action = INBOX.RECENT
      }else if(type === 'broadcast'){
        loadFunc = storage.getBroadcast
        setFunc = storage.setBroadcast
        loading = INBOX.BROADCAST_LOADING,
        action = INBOX.BROADCAST
      }
      else{
        const { inbox } = getState()
        query = {
          ty: inbox.filter.type
        }
      }

      dispatch({ type: loading, isLoading: true })
      let temp = loadFunc()
      if(temp){
        dispatch({ 
          type: action,
          data: JSON.parse(temp)
        })
      } 
      
      api.getInbox(query)
        .then((result: { 
          data: IInboxRes[];
        }) => {
          const inbox = result.data.map((inbox: IInboxRes) => ({
            id: inbox.id,
            type: inbox.type,
            title: inbox.title,
            ...(inbox.featured_image_url && {
              featuredImageUrl: inbox.featured_image_url
            }),
            ...(inbox.article_content && {
              articleContent: inbox.article_content
            }),
            publishDate: inbox.publish_date,
            contentType: inbox.content_type,
            isRead: inbox.is_read
          }))

          dispatch({
            type: action,
            data: inbox
          })
          dispatch({ type: loading, isLoading: false })
          resolve()
          setFunc(inbox)
        })
        .catch((err) => {
          dispatch({ type: INBOX.GET_LOADING, isLoading: false })
          logger(err, 'error', 'getInbox', false, err && err.response && err.response.data, query)
          reject(err)
        })
    })
  }
}


export const changeFilter = (isReset?: boolean) => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve) => {
      const { form } = getState()
    
      const data = isReset ? {
        type: settings.inbox.type,
      } : {
        type: form.inboxFilter.values.type,
      }

      dispatch({ type: INBOX.FILTER_LOADING, isLoadingFilter: true })
      dispatch({
        type: INBOX.FILTER,
        data
      })

      cookie.setInboxSetting(data)

      dispatch(getInbox('inbox'))
        .then(() => dispatch({ type: INBOX.FILTER_LOADING, isLoadingFilter: false }))
        .then(() => resolve())
    })
  }
}

export const getRecent = () => {
  return (dispatch: any) => {
    dispatch(getInbox('recent', {
      lt: 3
    }))
  }
}

export const getBroadcast = () => {
  return (dispatch: any) => {
    dispatch(getInbox('broadcast', {
      ty: ITYPE.MANAGEMENT,
      is_read: false
    }))
  }
}

export const clearInboxDetail = () => {
  return (dispatch: any) => {
    dispatch({ type: INBOX.DETAIL, data: null })
  }
}

export const markAsRead = (id: number) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      api.markAsRead(id)
        .then(() => {
          dispatch({
            type: INBOX.MARK_AS_READ,
            id: id
          })
          dispatch(getProfileStat())
          resolve()
        })
        .catch((err) => {
          logger(err, 'error', 'markAsRead', false, err && err.response && err.response.data, id)
          reject(err)
        })
    })
  }
}

export const getInboxDetail = (id: number ) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: INBOX.DETAIL_LOADING, isLoading: true })
      
      api.getInboxDetail(id)
        .then((result: { 
          data: IInboxDetailRes;
        }) => {
          const inbox = {
            id: result.data.id,
            type: result.data.type,
            title: result.data.title,
            ...(result.data.featured_image_url && {
              featuredImageUrl: result.data.featured_image_url
            }),
            publishDate: result.data.publish_date,
            contentType: result.data.content_type,
            isRead: result.data.is_read,
            contents: result.data.contents.map((content: IInboxContentRes) => ({
              ...(content.article_content && {
                articleContent: content.article_content
              }),
              ...(content.image_url && {
                imageUrl: content.image_url
              }),
              ...(content.video_url && {
                videoUrl: content.video_url
              })
            }))
          }

          dispatch({
            type: INBOX.DETAIL,
            data: inbox
          })
          dispatch({ type: INBOX.DETAIL_LOADING, isLoading: false })
          resolve(inbox)
        })
        .catch((err) => {
          dispatch({ type: INBOX.GET_LOADING, isLoading: false })
          logger(err, 'error', 'getInboxDetail', false, err && err.response && err.response.data, id)
          reject(err)
        })
    })
  }
}
