import { storage } from '../../utils'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { PT } from '../../config/constant'

/**
 * wrap component to make it accessable only if user is not authenticated
 * storage.isTokenExist should return false
 */
const isNotAuthenticated: any = connectedRouterRedirect({
  redirectPath: PT.HOMEPAGE,
  authenticatedSelector: () => !storage.isTokenExist()
})

export default isNotAuthenticated