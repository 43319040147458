import { INBOX, RESET } from '../../definitions'
import { IInbox } from '../../../config/interface'
import { settings } from '../../../config/config'
import { cookie } from '../../../utils'

const getSetting = cookie.getInboxSetting()
const initialSetting = getSetting ? JSON.parse(getSetting) : settings.inbox 

const initialState = {
  isLoading: false,
  isLoadingRecent: false,
  isLoadingBroadcast: false,
  isLoadingDetail: false,
  data: [] as IInbox[],
  recent: [] as IInbox[],
  broadcast: [] as IInbox[],
  detail: null,
  filter: {
    type: initialSetting.type,
  }
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    
    case INBOX.FILTER:
      return Object.assign({}, {
        ...state,
        filter: action.data
      })
    case INBOX.FILTER_LOADING:
      return Object.assign({}, {
        ...state,
        isLoadingFilter: action.isLoadingFilter
      })
    case INBOX.GET_LOADING:
      return Object.assign({}, {
        ...state,
        isLoading: action.isLoading
      })
    case INBOX.RECENT_LOADING:
      return Object.assign({}, {
        ...state,
        isLoadingRecent: action.isLoading
      })
    case INBOX.BROADCAST_LOADING:
      return Object.assign({}, {
        ...state,
        isLoadingBroadcast: action.isLoading
      })
    case INBOX.DETAIL_LOADING:
      return Object.assign({}, {
        ...state,
        isLoadingDetail: action.isLoading
      })
    case INBOX.GET:
      return Object.assign({}, {
        ...state,
        data: action.data,
      })
    case INBOX.RECENT:
      return Object.assign({}, {
        ...state,
        recent: action.data
      })
    case INBOX.BROADCAST:
      return Object.assign({}, {
        ...state,
        broadcast: action.data
      })
    case INBOX.DETAIL:
      return Object.assign({}, {
        ...state,
        detail: action.payload
      })
    case INBOX.MARK_AS_READ:
      const newData = [...state.data]
      const newRecent = [...state.recent]
      const newBroadcast = [...state.broadcast]
      const indexData = newData.findIndex(item => item.id === action.id)
      const indexRecent = newRecent.findIndex(item => item.id === action.id)
      const indexBroadcast = newBroadcast.findIndex(item => item.id === action.id)
      
      if(indexData > -1) newData[indexData].isRead = true
      if(indexRecent > -1) newRecent[indexRecent].isRead = true
      if(indexBroadcast > -1) newBroadcast[indexBroadcast].isRead = true
      
      return Object.assign({}, {
        ...state,
        data: newData,
        recent: newRecent,
        broadcast: newBroadcast,
      })
    case RESET.ALL: 
      return initialState
    default:
      return state
  }
}

