import React from 'react'
import classnames from 'classnames'
import './_index.scss'

interface IProp{
  className?: string;
  color?: string;
  type?: 'circle';
}

interface IState{
}

class Spinner extends React.Component<IProp, IState>{
  render() {
    const {
      className,
      color,
      //type
    } = this.props

    const classes = classnames('spinner',
      `spinner--${color}`,
      className)
      
    return (
      <div className={classes}>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    )
  }
}

export default Spinner