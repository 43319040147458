import { ATT, ALERT } from "../../definitions";
import { storage, cookie, logger } from "../../../utils";
import api from "../../../api";
import {
  IAttRes,
  IAttHistoryObjRes,
  IAttEndReq
} from "../../../config/interface";
import { alert } from "../../../utils";
import { STATUS, ATYPE } from "../../../config/constant";
import { getProfileStat } from "../profile";
import { hideAlert } from "../alert";
import moment from "moment";
import { settings } from "../../../config/config";

const getAttendance = (isLatest: boolean = false) => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      // Dispatch temporary data from localstorage
      dispatch({ type: ATT.GET_LOADING, isLoading: true });

      let temp = isLatest ? storage.getAttLatest() : storage.getAtt();
      if (temp) {
        dispatch({
          type: isLatest ? ATT.GET_LATEST : ATT.GET,
          data: JSON.parse(temp)
        });
      }

      const latest = {
        status: [
          STATUS.DRAFT,
          STATUS.SUBMITTED,
          STATUS.ON_REVISION,
          STATUS.APPROVED
        ].join(),
        lt: 3
      };

      const { attendance } = getState();

      const query = {
        ...(attendance.filter.start && {
          start: moment(attendance.filter.start).format("YYYY-MM-DD")
        }),
        ...(attendance.filter.end && {
          end: moment(attendance.filter.end).format("YYYY-MM-DD")
        }),
        ...(attendance.filter.isWorking.length > 0 && {
          is_working: attendance.filter.isWorking
            .map((option: any) => option.value)
            .join()
        }),
        ...(attendance.filter.status.length > 0 && {
          status: attendance.filter.status
            .map((option: any) => option.value)
            .join()
        }),
        dir: attendance.filter.dir
      };

      // Api call
      api
        .getAttendance(isLatest ? latest : query)
        .then((result: any) => {
          let attendance = result.data.map((att: IAttRes) => ({
            id: att.id,
            user: att.user,
            approver: att.approver,
            isWorking: att.is_working,
            status: att.status,
            date: att.date,
            ...(att.start &&
              att.start.timestamp && {
                start: {
                  timestamp: att.start.timestamp,
                  serverTimestamp: att.start.server_timestamp,
                  latitude: att.start.latitude,
                  longitude: att.start.longitude
                }
              }),
            ...(att.is_late && { isLate: att.is_late }),
            ...(att.absence_type && { absenceType: att.absence_type }),
            ...(att.absence_reason && { absenceReason: att.absence_reason }),
            ...(att.attachment_url && { attachmentUrl: att.attachment_url }),
            ...(att.end &&
              att.end.timestamp && {
                end: {
                  timestamp: att.end.timestamp,
                  serverTimestamp: att.end.server_timestamp,
                  latitude: att.end.latitude,
                  longitude: att.end.longitude
                }
              }),
            ...(att.status_history && {
              statusHistory: att.status_history.map(
                (his: IAttHistoryObjRes) => ({
                  status: his.status,
                  ...(his.remarks && { remarks: his.remarks }),
                  createdAt: his.created_at,
                  createdBy: his.created_by
                })
              )
            })
          }));

          if (isLatest) {
            storage.setAttLatest(attendance);
          } else {
            storage.setAtt(attendance);
          }

          return dispatch({
            type: isLatest ? ATT.GET_LATEST : ATT.GET,
            data: attendance
          });
        })
        .then(() => dispatch({ type: ATT.GET_LOADING, isLoading: false }))
        .then(() => resolve())
        .catch((err: any) => {
          dispatch({ type: ATT.GET_LOADING, isLoading: false });
          logger(
            err,
            "error",
            "getAttendance",
            false,
            err && err.response && err.response.data,
            isLatest ? latest : query
          );
          reject();
        });
    });
  };
};

const clearAttendanceDetail = () => {
  return (dispatch: any) => {
    dispatch({ type: ATT.GET_DETAIL, data: null });
  };
};

const getAttendanceDetail = (id: number) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      // Dispatch temporary data from localstorage
      dispatch({ type: ATT.GET_LOADING_DETAIL, isLoadingDetail: true });
      dispatch({ type: ATT.GET_DETAIL, data: null });

      // Api call
      api
        .getAttendanceDetail(id)
        .then((res: { data: IAttRes }) => {
          const att = res.data;
          let attendance = {
            id: att.id,
            user: att.user,
            approver: att.approver,
            isWorking: att.is_working,
            status: att.status,
            date: att.date,
            ...(att.start &&
              att.start.timestamp && {
                start: {
                  timestamp: att.start.timestamp,
                  serverTimestamp: att.start.server_timestamp,
                  latitude: att.start.latitude,
                  longitude: att.start.longitude
                }
              }),
            ...(att.is_late && { isLate: att.is_late }),
            ...(att.absence_type && { absenceType: att.absence_type }),
            ...(att.remarks && { remarksOptional: att.remarks }),
            ...(att.absence_reason && { absenceReason: att.absence_reason }),
            ...(att.attachment_url && { attachmentUrl: att.attachment_url }),
            ...(att.end &&
              att.end.timestamp && {
                end: {
                  timestamp: att.end.timestamp,
                  serverTimestamp: att.end.server_timestamp,
                  latitude: att.end.latitude,
                  longitude: att.end.longitude
                }
              }),
            ...(att.status_history && {
              statusHistory: att.status_history.map(
                (his: IAttHistoryObjRes) => ({
                  status: his.status,
                  ...(his.remarks && { remarks: his.remarks }),
                  createdAt: his.created_at,
                  createdBy: his.created_by
                })
              )
            })
          };

          dispatch({
            type: ATT.GET_DETAIL,
            data: attendance
          });

          dispatch(hideAlert());
          resolve(attendance);
        })
        .then(() =>
          dispatch({ type: ATT.GET_LOADING_DETAIL, isLoadingDetail: false })
        )
        .catch((err: any) => {
          dispatch({ type: ATT.GET_LOADING_DETAIL, isLoadingDetail: false });
          logger(
            err,
            "error",
            "getAttendanceDetail",
            false,
            err && err.response && err.response.data,
            id
          );
          reject();
        });
    });
  };
};

const getActiveAttendance = () => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      // Dispatch temporary data from localstorage
      dispatch({ type: ATT.GET_ACTIVE_LOADING, isLoadingActive: true });

      let temp = storage.getAttActive();
      if (temp) {
        dispatch({
          type: ATT.GET_ACTIVE,
          data: JSON.parse(temp)
        });
      }

      // Api call
      api
        .getActiveAttendance()
        .then((res: { data: IAttRes }) => {
          const att = res.data;
          let attendance =
            att && att.id
              ? {
                  id: att.id,
                  user: att.user,
                  approver: att.approver,
                  isWorking: att.is_working,
                  status: att.status,
                  date: att.date,
                  ...(att.start &&
                    att.start.timestamp && {
                      start: {
                        timestamp: att.start.timestamp,
                        serverTimestamp: att.start.server_timestamp,
                        latitude: att.start.latitude,
                        longitude: att.start.longitude
                      }
                    }),
                  ...(att.is_late && { isLate: att.is_late }),
                  ...(att.absence_type && { absenceType: att.absence_type }),
                  ...(att.absence_reason && {
                    absenceReason: att.absence_reason
                  }),
                  ...(att.attachment_url && {
                    attachmentUrl: att.attachment_url
                  }),
                  ...(att.end &&
                    att.end.timestamp && {
                      end: {
                        timestamp: att.end.timestamp,
                        serverTimestamp: att.end.server_timestamp,
                        latitude: att.end.latitude,
                        longitude: att.end.longitude
                      }
                    }),
                  ...(att.status_history && {
                    statusHistory: att.status_history.map(
                      (his: IAttHistoryObjRes) => ({
                        status: his.status,
                        ...(his.remarks && { remarks: his.remarks }),
                        createdAt: his.created_at,
                        createdBy: his.created_by
                      })
                    )
                  })
                }
              : null;

          dispatch({
            type: ATT.GET_ACTIVE,
            data: attendance
          });

          //resolve(attendance)
        })
        .then(() =>
          dispatch({ type: ATT.GET_ACTIVE_LOADING, isLoadingActive: false })
        )
        .catch((err: any) => {
          dispatch({ type: ATT.GET_ACTIVE_LOADING, isLoadingActive: false });
          logger(
            err,
            "error",
            "getActiveAttendance",
            false,
            err && err.response && err.response.data
          );
          reject();
        });
    });
  };
};

const changeFilter = (isReset?: boolean) => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      const { form } = getState();

      const data = isReset
        ? {
            start: settings.att.start,
            end: settings.att.end,
            isWorking: settings.att.isWorking,
            status: settings.att.status,
            dir: settings.att.dir
          }
        : {
            start: form.attFilter.values.daterange.startDate,
            end: form.attFilter.values.daterange.endDate,
            isWorking: form.attFilter.values.is_working
              ? form.attFilter.values.is_working
              : [],
            status: form.attFilter.values.status
              ? form.attFilter.values.status
              : [],
            dir: form.attFilter.values.dir
          };

      dispatch({ type: ATT.FILTER_LOADING, isLoadingFilter: true });
      dispatch({
        type: ATT.FILTER,
        data
      });

      cookie.setAttSetting(data);

      dispatch(getAttendance())
        .then(() =>
          dispatch({ type: ATT.FILTER_LOADING, isLoadingFilter: false })
        )
        .then(() => resolve())
        .catch((err: any) => {
          dispatch({ type: ATT.FILTER_LOADING, isLoadingFilter: false });
          dispatch({
            type: ALERT.SHOW,
            alert:
              err && err.response
                ? alert(
                    err.response.status,
                    "filterAttendance",
                    err.response.data && err.response.data.error
                      ? err.response.data.error.code
                      : null
                  )
                : alert(0, "filterAttendance")
          });
          reject();
        });
    });
  };
};

const uploadAttachment = (file: any) => {
  return new Promise((resolve, reject) => {
    let images = new FormData();
    images.append("image", file);

    api
      .uploadAttachment(images)
      .then((result: { data: { location: string } }) =>
        resolve(result.data.location)
      )
      .catch((err: any) => {
        logger(
          err,
          "error",
          "uploadAttachment",
          false,
          err && err.response && err.response.data,
          images
        );
        reject();
      });
  });
};

const endAttendance = (
  id: number,
  coords: any,
  isRevise?: boolean,
  attachment?: File | null | string
) => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      const { form, attendance } = getState();

      if (isRevise) {
        dispatch({ type: ATT.REVISE_LOADING, isRevising: true });
      } else {
        dispatch({ type: ATT.END_LOADING, isEnding: true });
      }

      const req = {
        is_working: form.attDetail.values.is_working === "yes" ? true : false,
        is_late: form.attDetail.values.is_late,
        approver_id: form.attDetail.values.approver_id,
        date:
          form.attDetail.values.is_working === "yes"
            ? moment(form.attDetail.values.start).format("YYYY-MM-DD")
            : moment(form.attDetail.values.date).format("YYYY-MM-DD"),
        ...(form.attDetail.values.is_working === "yes" && {
          start: {
            timestamp: moment(form.attDetail.values.start).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            latitude:
              attendance.detail && attendance.detail.start
                ? attendance.detail.start.latitude
                : coords.latitude,
            longitude:
              attendance.detail && attendance.detail.start
                ? attendance.detail.start.longitude
                : coords.longitude
          },
          end: {
            timestamp: moment(form.attDetail.values.end).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            latitude: coords.latitude,
            longitude: coords.longitude
          },
          ...(form.attDetail.values.is_late && {
            absence_reason: form.attDetail.values.absence_reason
          }),
          ...(form.attDetail.values.remarksOptional && {
            remarks: form.attDetail.values.remarksOptional
          })
        }),
        ...(form.attDetail.values.is_working === "no" && {
          absence_type: form.attDetail.values.absence_type,
          absence_reason: form.attDetail.values.absence_reason,
          ...(form.attDetail.values.absence_type === ATYPE.SICK &&
            (typeof attachment === "string" ||
              !!attendance.detail.attachmentUrl) && {
              attachment_url: attachment || attendance.detail.attachmentUrl
            })
        })
      };

      let newReq: IAttEndReq;
      // Api call
      if (isRevise) {
        if (attachment && typeof attachment === "object") {
          uploadAttachment(attachment)
            .then(url => {
              newReq = Object.assign({}, req, {
                attachment_url: url
              });
              return api.reviseAttendance(id, newReq);
            })
            .then(() => {
              dispatch(getActiveAttendance());
              dispatch(getAttendance(true));
              dispatch(getAttendance());
              return true;
            })
            .then(() =>
              dispatch({
                type: ALERT.SHOW,
                alert: alert(200, "reviseAttendance")
              })
            )
            .then(() =>
              dispatch({ type: ATT.REVISE_LOADING, isRevising: false })
            )
            .then(() => dispatch(getProfileStat()))
            .then(() => resolve())
            .catch((err: any) => {
              dispatch({ type: ATT.REVISE_LOADING, isRevising: false });
              dispatch({
                type: ALERT.SHOW,
                alert:
                  err && err.response
                    ? alert(
                        err.response.status,
                        "reviseAttendance",
                        err.response.data && err.response.data.error
                          ? err.response.data.error.code
                          : null
                      )
                    : alert(0, "reviseAttendance")
              });
              logger(
                err,
                "error",
                "reviseAttendance",
                false,
                err && err.response && err.response.data,
                { id, newReq }
              );
              reject();
            });
        } else {
          api
            .reviseAttendance(id, req)
            .then(() => {
              dispatch(getActiveAttendance());
              dispatch(getAttendance(true));
              dispatch(getAttendance());
              return true;
            })
            .then(() =>
              dispatch({
                type: ALERT.SHOW,
                alert: alert(200, "reviseAttendance")
              })
            )
            .then(() =>
              dispatch({ type: ATT.REVISE_LOADING, isRevising: false })
            )
            .then(() => dispatch(getProfileStat()))
            .then(() => resolve())
            .catch((err: any) => {
              dispatch({ type: ATT.REVISE_LOADING, isRevising: false });
              dispatch({
                type: ALERT.SHOW,
                alert:
                  err && err.response
                    ? alert(
                        err.response.status,
                        "reviseAttendance",
                        err.response.data && err.response.data.error
                          ? err.response.data.error.code
                          : null
                      )
                    : alert(0, "reviseAttendance")
              });
              logger(
                err,
                "error",
                "reviseAttendanceNoUpload",
                false,
                err && err.response && err.response.data,
                { id, req }
              );
              reject();
            });
        }
      } else {
        
        if (attachment && typeof attachment === "object") {
          uploadAttachment(attachment)
            .then(url => {
              newReq = Object.assign({}, req, {
                attachment_url: url
              });
              
              return api
                .endAttendance(id, newReq)
            })
            .then(() => {
              dispatch(getActiveAttendance());
              dispatch(getAttendance(true));
              dispatch(getAttendance());
              return true;
            })
            .then(() =>
              dispatch({
                type: ALERT.SHOW,
                alert: alert(200, "reviseAttendance")
              })
            )
            .then(() => {
              dispatch(getActiveAttendance());
              dispatch(getAttendance(true));
              dispatch(getAttendance());
              return true;
            })
            .then(() =>
              dispatch({ type: ALERT.SHOW, alert: alert(200, "endAttendance") })
            )
            .then(() => dispatch({ type: ATT.END_LOADING, isEnding: false }))
            .then(() => resolve())
            .catch((err: any) => {
              dispatch({ type: ATT.END_LOADING, isEnding: false });
              dispatch({
                type: ALERT.SHOW,
                alert:
              err && err.response
                ? alert(
                  err.response.status,
                  "endAttendance",
                  err.response.data && err.response.data.error
                    ? err.response.data.error.code
                    : null
                )
                : alert(0, "endAttendance")
              });
              logger(
                err,
                "error",
                "endAttendance",
                false,
                err && err.response && err.response.data,
                { id, req }
              );
              reject();
            });
        } else{
          
          api
            .endAttendance(id, req)
            .then(() => {
              dispatch(getActiveAttendance());
              dispatch(getAttendance(true));
              dispatch(getAttendance());
              return true;
            })
            .then(() =>
              dispatch({ type: ALERT.SHOW, alert: alert(200, "endAttendance") })
            )
            .then(() => dispatch({ type: ATT.END_LOADING, isEnding: false }))
            .then(() => resolve())
            .catch((err: any) => {
              dispatch({ type: ATT.END_LOADING, isEnding: false });
              dispatch({
                type: ALERT.SHOW,
                alert:
              err && err.response
                ? alert(
                  err.response.status,
                  "endAttendance",
                  err.response.data && err.response.data.error
                    ? err.response.data.error.code
                    : null
                )
                : alert(0, "endAttendance")
              });
              logger(
                err,
                "error",
                "endAttendance",
                false,
                err && err.response && err.response.data,
                { id, req }
              );
              reject();
            });
        }
      }
    });
  };
};

const startAttendance = (coords: any, file?: File | null) => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      const { form } = getState();

      dispatch({ type: ATT.START_LOADING, isStarting: true });

      const req = {
        is_working: form.attDetail.values.is_working === "yes" ? true : false,
        is_late: form.attDetail.values.is_late,
        approver_id: form.attDetail.values.approver_id,
        date:
          form.attDetail.values.is_working === "yes"
            ? moment(form.attDetail.values.start).format("YYYY-MM-DD")
            : moment(form.attDetail.values.date).format("YYYY-MM-DD"),
        ...(form.attDetail.values.is_working === "yes" &&
          form.attDetail.values.is_late && {
            absence_reason: form.attDetail.values.absence_reason
          }),
        ...(form.attDetail.values.is_working === "yes" && {
          start: {
            timestamp: moment(form.attDetail.values.start).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            latitude: coords.latitude,
            longitude: coords.longitude
          }
        }),
        ...(form.attDetail.values.is_working === "yes" &&
          form.attDetail.values.remarksOptional && {
            remarks: form.attDetail.values.remarksOptional
          }),
        ...(form.attDetail.values.is_working === "no" && {
          absence_type: form.attDetail.values.absence_type,
          absence_reason: form.attDetail.values.absence_reason
        })
      };

      let newReq: IAttEndReq;
      if (
        form.attDetail.values.is_working === "no" &&
        form.attDetail.values.absence_type === ATYPE.SICK &&
        file
      ) {
        uploadAttachment(file)
          .then(url => {
            newReq = Object.assign({}, req, {
              attachment_url: url
            });
            return api.startAttendance(newReq);
          })
          .then((res: { data: IAttRes }) => {
            if (!res.data.is_working) {
              return dispatch(
                endAttendance(
                  res.data.id,
                  coords,
                  false,
                  res.data.attachment_url
                )
              );
            } else {
              dispatch(getActiveAttendance());
              dispatch(getAttendance(true));
              dispatch(getAttendance());
              return true;
            }
          })
          .then(() =>
            dispatch({ type: ALERT.SHOW, alert: alert(200, "startAttendance") })
          )
          .then(() => dispatch({ type: ATT.START_LOADING, isStarting: false }))
          .then(() => resolve())
          .catch((err: any) => {
            dispatch({ type: ATT.START_LOADING, isStarting: false });
            dispatch({
              type: ALERT.SHOW,
              alert:
                err && err.response
                  ? alert(
                      err.response.status,
                      "startAttendance",
                      err.response.data && err.response.data.error
                        ? err.response.data.error.code
                        : null
                    )
                  : alert(0, "startAttendance")
            });
            logger(
              err,
              "error",
              "startAttendance",
              false,
              err && err.response && err.response.data,
              newReq
            );
            reject();
          });
      } else if (form.attDetail.values.is_working === "yes" && file) {
        uploadAttachment(file).then(url => {
          newReq = Object.assign({}, req, {
            attachment_url: url
          });
          // Api call
          return api
            .startAttendance(newReq)
            .then((res: { data: IAttRes }) => {
              console.log("startAttendance with file ", res);
              if (!res.data.is_working) {
                return dispatch(endAttendance(res.data.id, coords));
              } else {
                dispatch(getActiveAttendance());
                dispatch(getAttendance(true));
                dispatch(getAttendance());
                return true;
              }
            })
            .then(() =>
              dispatch({
                type: ALERT.SHOW,
                alert: alert(200, "startAttendance")
              })
            )
            .then(() =>
              dispatch({ type: ATT.START_LOADING, isStarting: false })
            )
            .then(() => resolve())
            .catch((err: any) => {
              dispatch({ type: ATT.START_LOADING, isStarting: false });
              dispatch({
                type: ALERT.SHOW,
                alert:
                  err && err.response
                    ? alert(
                        err.response.status,
                        "startAttendance",
                        err.response.data && err.response.data.error
                          ? err.response.data.error.code
                          : null
                      )
                    : alert(0, "startAttendance")
              });
              logger(
                err,
                "error",
                "startAttendanceWorkingWithUpload",
                false,
                err && err.response && err.response.data,
                req
              );
              reject();
            });
        });
      } else {
        // Api call
        api
          .startAttendance(req)
          .then((res: { data: IAttRes }) => {
            if (!res.data.is_working) {
              return dispatch(endAttendance(res.data.id, coords));
            } else {
              dispatch(getActiveAttendance());
              dispatch(getAttendance(true));
              dispatch(getAttendance());
              return true;
            }
          })
          .then(() =>
            dispatch({ type: ALERT.SHOW, alert: alert(200, "startAttendance") })
          )
          .then(() => dispatch({ type: ATT.START_LOADING, isStarting: false }))
          .then(() => resolve())
          .catch((err: any) => {
            dispatch({ type: ATT.START_LOADING, isStarting: false });
            dispatch({
              type: ALERT.SHOW,
              alert:
                err && err.response
                  ? alert(
                      err.response.status,
                      "startAttendance",
                      err.response.data && err.response.data.error
                        ? err.response.data.error.code
                        : null
                    )
                  : alert(0, "startAttendance")
            });
            logger(
              err,
              "error",
              "startAttendanceNoUpload",
              false,
              err && err.response && err.response.data,
              req
            );
            reject();
          });
      }
    });
  };
};

const deleteAttendance = (id: number) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: ATT.START_DELETING, isDeleting: true });

      // Api call
      api
        .deleteAttendance(id)
        .then(() => {
          dispatch(getActiveAttendance());
          dispatch(getAttendance(true));
          dispatch(getAttendance());
        })
        .then(() =>
          dispatch({ type: ALERT.SHOW, alert: alert(200, "deleteAttendance") })
        )
        .then(() => dispatch({ type: ATT.START_DELETING, isDeleting: false }))
        .then(() => resolve())
        .catch((err: any) => {
          dispatch({ type: ATT.START_DELETING, isDeleting: false });
          dispatch({
            type: ALERT.SHOW,
            alert:
              err && err.response
                ? alert(
                    err.response.status,
                    "deleteAttendance",
                    err.response.data && err.response.data.error
                      ? err.response.data.error.code
                      : null
                  )
                : alert(0, "deleteAttendance")
          });
          logger(
            err,
            "error",
            "deleteAttendance",
            false,
            err && err.response && err.response.data,
            id
          );
          reject();
        });
    });
  };
};

export {
  getAttendance,
  getAttendanceDetail,
  changeFilter,
  startAttendance,
  deleteAttendance,
  clearAttendanceDetail,
  endAttendance,
  getActiveAttendance
};
