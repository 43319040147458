import { CONF, RESET } from '../../definitions'
import { storage } from '../../../utils'
import { settings } from '../../../config/config'

const initialState = {
  lang: storage.getLang() || settings.lang
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CONF.LANG:
      return Object.assign({}, {
        lang: action.lang
      })
    
    case RESET.ALL: 
      return initialState
      
    default:
      return state
  }
}