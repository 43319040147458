import { MASTER, RESET } from '../../definitions'

const initialState = {
  isLoading: false,
  data: {
    approver: []
  }
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case MASTER.GET:
      return Object.assign({}, {
        ...state,
        data: action.data
      })
  
    case MASTER.GET_LOADING:
      return Object.assign({}, {
        ...state,
        isLoading: action.isLoading
      })
    
    case RESET.ALL: 
      return initialState

    default:
      return state
  }
}