// Name for localstorage
export enum LS {
  TOKEN = "BAP-ATTENDANCE-TOKEN",
  LANG = "BAP-ATTENDANCE-LANG",
  PROFILE = "BAP-ATTENDANCE-PROFILE",
  PROFILE_STAT = "BAP-ATTENDANCE-PROFILE-STAT",
  MASTER = "BAP-ATTENDANCE-MASTER",
  ATT = "BAP-ATTENDANCE-ATT",
  ATT_LATEST = "BAP-ATTENDANCE-ATT-LATEST",
  ATT_ACTIVE = "BAP-ATTENDANCE-ATT-ACtIVE",
  APP = "BAP-ATTENDANCE-APP",
  INBOX = "BAP-ATTENDANCE-INBOX",
  RECENT = "BAP-ATTENDANCE-INBOX-RECENT",
  BROADCAST = "BAP-ATTENDANCE-INBOX-BROADCAST",
  EMERGENCY = " BAP-ATTENDANCE-INBOX-EMERGENCY",
  TEMP_EMERGENCY = "BAP-ATTENDANCE-TEMP-EMERGENCY",
}

// Name for cookie
export enum CK {
  APP = "BAP-ATTENDANCE-APP-APPROVAL-FILTER",
  INB = "BAP-ATTENDANCE-APP-INBOX-FILTER",
  ATT = "BAP-ATTENDANCE-APP-ATTENDANCE-FILTER",
  VISIT = "BAP-ATTENDANCE-APP-VISIT",
}

// Name for Path
export enum PT {
  SIGNIN = "/signin",
  HOMEPAGE = "/homepage",
  ATTENDANCE = "/attendance",
  APPROVAL = "/approval",
  BROADCAST = "/broadcast",
  SETTING = "/setting",
  INBOX = "/inbox",
}

// Leave reason
export enum ATYPE {
  SICK = "SAKIT",
  PERMIT = "IZIN",
}

// Attendance status
export enum STATUS {
  DRAFT = 101,
  DELETED = 102,
  SUBMITTED = 201,
  ON_REVISION = 202,
  APPROVED = 301,
}

// User status
export enum USTATUS {
  NEW = 101,
  ACTIVE = 201,
  INACTIVE = 202,
}

// language
export enum LANG {
  EN = "en",
  ID = "id",
}

// Inbox type
export enum ITYPE {
  MANAGEMENT = 1,
  SAFETY = 2,
}

// Content type
export enum CTYPE {
  ARTICLE = "article",
  IMAGE = "image",
  VIDEO = "video",
}

export enum MESSAGE_TYPE {
  MANAGEMENT = 1,
  SAFETY = 2,
}

export enum CONTENT_TYPE {
  ARTICLE = "article",
  IMAGE = "image",
  VIDEO = "video",
}

export const mimeList = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "video/mp4",
  "video/quicktime",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "text/plain",
];
