import Cookies from 'js-cookie'
import { CK } from '../../config/constant'
import moment from 'moment'

// ------------------------------- //
// Get settings
export const getAppSetting = () => Cookies.get(CK.APP)

// Set settings
export const setAppSetting = (setting: any) => Cookies.set(CK.APP, JSON.stringify(setting))

// Remove settings
export const removeAppSetting = () => Cookies.remove(CK.APP)

// ------------------------------- //
// Get inbox settings
export const getInboxSetting = () => Cookies.get(CK.INB)

// Set inbox settings
export const setInboxSetting = (setting: any) => Cookies.set(CK.INB, JSON.stringify(setting))

// Remove inbox settings
export const removeInboxSetting = () => Cookies.remove(CK.INB)

// ------------------------------- //
// Get settings
export const getAttSetting = () => Cookies.get(CK.ATT)

// Set settings
export const setAttSetting = (setting: any) => Cookies.set(CK.ATT, JSON.stringify(setting))

// Remove settings
export const removeAttSetting = () => Cookies.remove(CK.ATT)


// ------------------------------- //
// Get first visit
export const getFirstVisit = () => Cookies.get(CK.VISIT)

// Set first visit
export const setFirstVisit = () => Cookies.set(CK.VISIT, moment().toDate(), { expires: 15 })