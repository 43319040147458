import { EMERGENCY, ALERT } from '../../definitions'
import { IEmergency, IEmergencyReq } from '../../../config/interface'
import { alert, logger } from '../../../utils'
import api from '../../../api'

export const getEmergency = () => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      api.getEmergency()
        .then((res: { data: IEmergency[]}) => {
          const emergency = res.data.map((emergency: IEmergency) => ({
            id: emergency.id,
            content: emergency.content,
            title: emergency.title
          }))
          dispatch({ type: EMERGENCY.GET, data: emergency })
          resolve()
        })
        .catch((err) => {
          logger(err, 'error', 'getEmergency', false, err && err.response && err.response.data)
          reject(err)
        })
    })
  }
}

export const setEmergencyRes = (id: number, text?: string) => {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      let req: IEmergencyReq = { 
        is_safe: !text,
        ...(text && {
          message: text
        })
      }

      api.setEmergencyRes(id, req)
        .then(() => {
          dispatch(getEmergency())
          dispatch({ type: ALERT.SHOW, alert: alert(200, 'setEmergency')})
          resolve()
        })
        .catch((err) => {
          logger(err, 'error', 'setEmergencyRes', false, err && err.response && err.response.data, { id, req })
          reject()
        })  
    })
  }
}
