import React from 'react'
import classnames from 'classnames'
import './_index.scss'

interface IProp{
  children: any;
  className?: string;
  isCollapse?: boolean;
  isSidebarOnDesktop?: boolean;
}

interface IState{
}

export class Navigation extends React.Component<IProp, IState>{
  render(){
    const { children, isSidebarOnDesktop, className, isCollapse } = this.props
    const classes = 
      classnames('navigation',
        { 'navigation--sidebar' : isSidebarOnDesktop},
        { 'navigation--is-collapse' : isCollapse},
        className)

    return(
      <div className={classes}>
        {children}
      </div>
    )
  }
}

export default Navigation