import { ATT, RESET } from '../../definitions'
import { settings } from '../../../config/config'
import { cookie } from '../../../utils'

const getSetting = cookie.getAttSetting()
const initialSetting = getSetting ? JSON.parse(getSetting) : settings.att 

const initialState = {
  isLoadingDetail: false,
  isLoadingActive: false,
  isLoading: false,
  isLoadingFilter: false,
  isStarting: false,
  isEnding: false,
  isDeleting: false,
  isRevising: false,
  detail: null,
  data: [],
  latest: [],
  active: null,
  filter: {
    start: initialSetting.start,
    end: initialSetting.end,
    isWorking: initialSetting.isWorking,
    status: initialSetting.status,
    dir: initialSetting.dir
  }
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ATT.GET:
      return Object.assign({}, {
        ...state,
        data: action.data
      })

    case ATT.GET_DETAIL:
      return Object.assign({}, {
        ...state,
        detail: action.data
      })

    case ATT.GET_LATEST:
      return Object.assign({}, {
        ...state,
        latest: action.data
      })

    case ATT.GET_ACTIVE:
      return Object.assign({}, {
        ...state,
        active: action.data
      })

    case ATT.GET_ACTIVE_LOADING:
      return Object.assign({}, {
        ...state,
        isLoadingActive: action.isLoadingActive
      })
  
    case ATT.GET_LOADING:
      return Object.assign({}, {
        ...state,
        isLoading: action.isLoading
      })
    
    case ATT.FILTER:
      return Object.assign({}, {
        ...state,
        filter: action.data
      })

    case ATT.FILTER_LOADING:
      return Object.assign({}, {
        ...state,
        isLoadingFilter: action.isLoadingFilter
      })
    
    case ATT.GET_LOADING_DETAIL:
      return Object.assign({}, {
        ...state,
        isLoadingDetail: action.isLoadingDetail
      })
    
    case ATT.START_LOADING:
      return Object.assign({}, {
        ...state,
        isStarting: action.isStarting
      })

    case ATT.REVISE_LOADING:
      return Object.assign({}, {
        ...state,
        isRevising: action.isRevising
      })

    case ATT.END_LOADING:
      return Object.assign({}, {
        ...state,
        isEnding: action.isEnding
      })

    case ATT.START_DELETING:
      return Object.assign({}, {
        ...state,
        isDeleting: action.isDeleting
      })
    
    case RESET.ALL: 
      return initialState

    default:
      return state
  }
}