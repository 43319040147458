import { IEnvironment } from './interface'

// Available environments
const dev: IEnvironment = {
  api: 'https://dev-bap-api.dutfin.net',
  asset: {
    reg: 'https://dev-bap-api.dutfin.net/media/downloads/bap_company_reg.pdf'
  }
}

const uat: IEnvironment = {
  api: 'https://uat-api.bapportalkaryawan.com',
  asset: {
    reg: 'https://uat-api.bapportalkaryawan.com/media/downloads/bap_company_reg.pdf'
  }
}

const prod: IEnvironment = {
  api: 'https://api.bapportalkaryawan.com',
  asset: {
    reg: 'https://api.bapportalkaryawan.com/media/downloads/bap_company_reg.pdf'
  }
}

// Return environment object based on NODE_ENV
const env: IEnvironment = 
  process.env.REACT_APP_ENV ? 
    process.env.REACT_APP_ENV.trim() === 'production' ? prod : 
      process.env.REACT_APP_ENV.trim() === 'uat' ? uat :
        dev : dev

export default env