import { SIGNIN, RESET } from '../../definitions'

const initialState = {
  isSigning: false
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SIGNIN.LOADING:
      return Object.assign({}, {
        ...state,
        isSigning: action.isSigning
      })
    
    case RESET.ALL:
      return initialState

    default:
      return state
  }
}