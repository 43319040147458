import React from 'react'
import { NavLink } from 'react-router-dom'
import { intlShape } from 'react-intl'
import classnames from 'classnames'
import Icon from '../Icon'
import './_index.scss'

interface IProp {
  target?: string;
  icon: any;
  label: string;
  className?: string;
  counter?: number;
  showCounter?: boolean;
  withLine?: boolean;
  isSidebarOnDesktop?: boolean;
  isCollapseButton?: boolean;
  onClick?: any;
}

interface IState {
}

class NavigationItem extends React.Component<IProp, IState> {
  static contextTypes = {
    intl: intlShape,
  }

  componentDidMount(){
  }

  componentDidUpdate(){
  }

  render() {
    const {
      target,
      icon, 
      label,
      className,
      counter,
      showCounter,
      withLine,
      isSidebarOnDesktop,
      isCollapseButton,
      ...others
    } = this.props

    const {
      intl
    } = this.context
    
    const classes = 
      classnames('navigation-item',
        { 'navigation-item--with-line': withLine },
        { 'navigation-item--sidebar': isSidebarOnDesktop },
        { 'navigation-item--is-collapse-button': isCollapseButton },
        className)

    return (
      isCollapseButton ? 
        <div 
          className={classes}
          {...others}>
          <div className="navigation-item__icon">
            <Icon data={icon} />
          </div>
        
          <div className="navigation-item__label">
            <span>{intl.formatMessage({ id: label, defaultMessage: label})}</span>
          </div>

          { showCounter && 
          <span className="navigation-item__counter">
            { counter } 
          </span>
          }
        </div>
        :
        <NavLink 
          to={target || '/'}
          className={classes}
          activeClassName="active"
          {...others}>
          <div className="navigation-item__icon">
            <Icon data={icon} />
          </div>
        
          <div className="navigation-item__label">
            <span>{intl.formatMessage({ id: label, defaultMessage: label})}</span>
          </div>

          { showCounter && 
          <span className="navigation-item__counter">
            { counter } 
          </span>
          }
        </NavLink>
    )
  }
}

export default NavigationItem