import React from 'react'
import { PT } from '../../config/constant'
import { Navigation, NavigationItem } from '../../components/common'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getProfileStat } from '../../store/actions/profile'
import { bindActionCreators } from 'redux'
import {
  homepageIcon,
  approvalIcon,
  broadcastIcon,
  attendanceIcon,
  settingIcon
} from '../../config/icon'
import './_index.scss'
import { IProfileStat } from '../../config/interface';

interface IProp extends RouteComponentProps {
  isSignedIn: () => any;
  getProfileStat: () => any;
  isApprover: boolean;
  stat: IProfileStat;
}

interface IState {
}

class NavigationBar extends React.Component<IProp, IState> {
  private timer: any

  constructor(props: IProp) {
    super(props)

    this.timer = null
  }

  componentDidMount() {
    if (!this.timer) {
      this.props.getProfileStat()
      this.timer = setInterval(() => this.props.getProfileStat(), 15000)
    }
  }

  componentDidUpdate(prevProps: IProp) {
    if (prevProps.isSignedIn !== this.props.isSignedIn) {
      if (this.props.isSignedIn) {
        this.props.getProfileStat()
        this.timer = setInterval(() => this.props.getProfileStat(), 15000)
      } else {
        clearInterval(this.timer)
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { isApprover, stat } = this.props

    return (
      <Navigation>
        <NavigationItem
          target={PT.HOMEPAGE}
          icon={homepageIcon}
          label="menuHomepage"
          withLine
        />

        <NavigationItem
          target={PT.ATTENDANCE}
          icon={attendanceIcon}
          label="menuAttendance"
          counter={stat.rejectedAttendance}
          showCounter={!!stat.rejectedAttendance}
          withLine
        />

        {isApprover &&
          <NavigationItem
            target={PT.APPROVAL}
            icon={approvalIcon}
            label="menuApproval"
            counter={stat.outstandingApproval}
            showCounter={!!stat.outstandingApproval}
            withLine
          />
        }

        <NavigationItem
          target={PT.INBOX}
          icon={broadcastIcon}
          label="menuBroadcast"
          counter={stat.unreadInbox}
          showCounter={!!stat.unreadInbox}
          withLine
        />

        <NavigationItem
          target={PT.SETTING}
          icon={settingIcon}
          label="menuSetting"
          withLine
        />
      </Navigation>
    )
  }
}

const mapStateToProps = (state: any) => ({
  isSignedIn: state.profile.isSignedIn,
  isApprover: state.profile.data.isApprover,
  stat: state.profile.stat,
  lang: state.config.lang
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getProfileStat,
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar))