import { PROF, RESET } from '../../definitions'

const initialState = {
  isLoading: false,
  isSignedIn: false,
  isEditing: false,
  isChanging: false,
  data: {
    isApprover: false
  },
  stat: {
  }
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PROF.GET_SIGNED:
      return Object.assign({}, {
        ...state,
        isSignedIn: action.isSignedIn
      })

    case PROF.GET:
      return Object.assign({}, {
        ...state,
        data: action.data
      })
  
    case PROF.GET_STAT:
      return Object.assign({}, {
        ...state,
        stat: action.data
      })
    
    case PROF.GET_LOADING:
      return Object.assign({}, {
        ...state,
        isLoading: action.isLoading
      })
    
    case PROF.PASS_CHANGING: 
      return Object.assign({}, {
        ...state,
        isChanging: action.isChanging
      })

    case RESET.ALL: 
      return initialState

    default:
      return state
  }
}