import api from '../../../api'
import { PROF, ALERT } from '../../definitions'
import { storage } from '../../../utils'
import { IProfileRes, IProfileStatRes } from '../../../config/interface'
import { alert, logger } from '../../../utils'
import { signout } from '../signout'
import { getAttendance } from '../attendance'
import { getApproval } from '../approval'
import { getEmergency } from '../emergency'
import { initUserLog } from '../../../utils/logger'
import { getInbox, getRecent, getBroadcast } from '../inbox'

const getProfile = () => {  
  return (dispatch: any) => {
    // Dispatch temporary data from localstorage
    dispatch({ type: PROF.GET_LOADING, isLoading: true })
    
    let tempProfile = storage.getProfile()
    if(tempProfile){
      dispatch({ type: PROF.GET_SIGNED, isSignedIn: true})
      dispatch({ 
        type: PROF.GET, 
        data: JSON.parse(tempProfile)
      })
    } 

    // Api call
    api.getProfile()
      .then((result: IProfileRes ) => {
        let profile = {
          id: result.data.id,
          name: result.data.name,
          employeeCode: result.data.employee_code,
          isApprover: result.data.is_approver,
          status: result.data.status
        }

        storage.setProfile(profile)
        initUserLog(profile)
        
        return dispatch({
          type: PROF.GET,
          data: profile
        })
      })
      .then(() => dispatch({ type: PROF.GET_SIGNED, isSignedIn: true}))
      .then(() => dispatch({ type: PROF.GET_LOADING, isLoading: false }))
      .catch((err) => {
        logger(err, 'error', 'getProfile', false, err && err.response && err.response.data)
        dispatch({ type: PROF.GET_LOADING, isLoading: false })
      })
  }
}

const getProfileStat = () => {
  return (dispatch: any, getState: any) => {
    let tempProfileStat = storage.getProfileStat()
    
    const { profile } = getState()

    if(tempProfileStat){
      dispatch({ 
        type: PROF.GET_STAT, 
        data: JSON.parse(tempProfileStat)
      })
    } 

    // Api call
    api.getProfileStat()
      .then((result: IProfileStatRes) => {
        let profileStat = {
          unreadInbox: result.data.unread_inbox,
          rejectedAttendance: result.data.rejected_attendance,
          outstandingApproval: result.data.outstanding_approval,
          emergencyMessage: result.data.emergency_message
        }

        if(profile.stat.rejectedAttendance !== result.data.rejected_attendance){
          dispatch(getAttendance())
          dispatch(getAttendance(true))
        }

        if(profile.stat.emergencyMessage !== result.data.emergency_message){
          dispatch(getEmergency())
        }
        
        if(profile.data.isApprover &&
          profile.stat.outstandingApproval !== result.data.outstanding_approval){
          dispatch(getApproval())
        }

        if(profile.stat.unreadInbox !== result.data.unread_inbox){
          dispatch(getInbox())
          dispatch(getRecent())
          dispatch(getBroadcast())
        }

        storage.setProfileStat(profileStat)

        return dispatch({
          type: PROF.GET_STAT,
          data: profileStat
        })
      })
      .catch((err: any) => {
        logger(err, 'error', 'getProfileStat', false, err && err.response && err.response.data)
      })
  }
}

const changePassword = () => {
  return (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: PROF.PASS_CHANGING, isChanging: true })

      const { form } = getState()
  
      api.changePassword({
        old_password: form.changePassword.values.old_password,
        new_password: form.changePassword.values.new_password,
      })
        .then(() => {
          dispatch(signout())
          dispatch({ type: ALERT.SHOW, alert: alert(200, 'changePassword') })
          dispatch({ type: PROF.PASS_CHANGING, isChanging: false })
          resolve()
        })
        .catch((err: any) => {
          dispatch({ type: PROF.PASS_CHANGING, isChanging: false })
          dispatch({ type: ALERT.SHOW, alert: err && err.response ? 
            alert(err.response.status, 'changePassword', err.response.data && err.response.data.error ? err.response.data.error.code : null ): 
            alert(0, 'changePassword') })
          logger(err, 'error', 'changePassword', false, err && err.response && err.response.data)
          reject()
        })
    })
  }
}

export { getProfile, getProfileStat, changePassword }