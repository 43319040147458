// Import redux
import { combineReducers } from 'redux'

// Import router and redux form 
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import config from './config'
import alert from './alert'
import profile from './profile'
import master from './master'
import signin from './signin'
import attendance from './attendance'
import approval from './approval'
import inbox from './inbox'
import emergency from './emergency'

/**
 * Combine all reducers
 * @param {*} history 
 * history to track router change to state
 */
const reducers = (history: any) => combineReducers<{}>({
  router: connectRouter(history),
  form: formReducer,
  config,
  alert,
  profile,
  master,
  signin,
  attendance,
  approval,
  inbox,
  emergency,
},)

export default reducers